import React from 'react';
import './App.css';  

const handleDownload = (url) => {  
  const iframe = document.createElement('iframe');  
  iframe.style.display = 'none';  
  iframe.src = url;  
  document.body.appendChild(iframe);  
};  

const CS = ({ files }) => (  
  <div className="App">  
    <h1>Machine Readable Data</h1>  
    <h2>Machine Readable Data for Provider Network and Prescription Formulary Content for Health Plans</h2>  
    <p>  
      As required by the Centers for Medicare & Medicaid Services (CMS) Medicaid Managed Care Rule, health plans are to publish provider directories and formulary drug lists on their website in a machine-readable format.   
    </p> 
    <p>This requirement increases and enhances consumer transparency by allowing software developers to access up-to-date, accurate and complete formulary and provider data needed to create innovative and informative tools.</p> 
    <p>In accordance with this requirement, the following files are available for use:</p>
  
    <div className="data-sections">  
    <div className="section">  
        <h3>Providers</h3>  
        {files.providers.filter(file => file.name.endsWith('.json')).map((file, index) => (  
          <div key={index}>  
            <a href={file.url}>{file.name}</a> ({new Date(file.date).toLocaleString()})  
          </div> 
  ))}  
</div>   
      <div className="section">  
        <h3>Drugs</h3>  
        {files.drugs.filter(file => file.name.endsWith('.json')).map((file, index) => (  
          <div key={index}>  
            {/* The Drugs section remains unchanged */}  
            <a href={file.url} download>{file.name}</a> ({new Date(file.date).toLocaleString()})  
          </div>  
        ))}  
      </div>  
    </div>  
  </div>  
);  
  
export default CS;