import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation,useParams } from 'react-router-dom';
import { BlobServiceClient } from '@azure/storage-blob';
import CS from './CS'; 
import IFP from './IFP'; 

const App = () => {
  const [files, setFiles] = useState({ providers: [], drugs: [], plans: [] });

  const fetchFilesFromDirectory = async (directoryPath) => {  
    try {  
      const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;  
      const containerName = "cmsmrfui";  
      const sasToken = process.env.REACT_APP_SAS_TOKEN_MRF;  
      const sasTokenWithQuestionMark = sasToken.startsWith('?') ? sasToken : `?${sasToken}`;  
      const blobServiceClient = new BlobServiceClient(  
        `https://${storageAccountName}.blob.core.windows.net${sasTokenWithQuestionMark}`  
      );  
    
      const containerClient = blobServiceClient.getContainerClient(containerName);  
      let blobs = containerClient.listBlobsFlat({ prefix: directoryPath });  
      let fileList = { providers: [], drugs: [], plans: [] };  
    
      for await (const blob of blobs) {  
        console.log("Blob found:", blob.name);  
    
        const pathSegments = blob.name.split('/');  
    
        if (pathSegments.length >= 2) {  
          const subdirectory = pathSegments[2];  
    
          if (subdirectory === "providers") {  
            const fileName = encodeURIComponent(blob.name.split('/').pop());  
            const rscd = `&rscd=inline%3B%20filename%3D${fileName}`;  
            const cacheBuster = `&cb=${new Date().getTime()}`; // Cache-busting query parameter
    
            fileList.providers.push({  
              name: blob.name.split('/').pop(),  
              date: blob.properties.lastModified,  
              url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}${sasTokenWithQuestionMark}${rscd}${cacheBuster}`  
            });  

            if (blob.name.endsWith('provider_link_from_drugs_file.csv')) {
              const fileData = {
                name: blob.name,
                date: blob.properties.lastModified,  
                url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}${sasTokenWithQuestionMark}${cacheBuster}`
              };
              const response = await fetch(fileData.url);
              const text = await response.text();
              const results = [];
              text.split('\n').forEach(line => {
                const [filename, link] = line.split(',');
                if (filename && link) {
                  results.push({ name: filename.trim(), url: link.trim(), date: fileData.date });
                }
              });
              fileList.providers.push(...results);
            }
  
          } else if (subdirectory === "drugs") {
            const cacheBuster = `&cb=${new Date().getTime()}`; // Cache-busting query parameter
            const fileData = {
              name: blob.name,
              date: blob.properties.lastModified,  
              url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}${sasTokenWithQuestionMark}${cacheBuster}`
            };
  
            if (blob.name.endsWith('.csv')) {
              const response = await fetch(fileData.url);
              const text = await response.text();
              const results = [];
              text.split('\n').forEach(line => {
                const [filename, link] = line.split(',');
                if (filename && link) {
                  results.push({ name: filename.trim(), url: link.trim(), date: fileData.date });
                }
              });
              fileList.drugs.push(...results);
            } else if (blob.name.endsWith('.json')) {
              const response = await fetch(fileData.url);
              const jsonContent = await response.json();
              const results = jsonContent.map(item => ({  
                name: item.filename,
                url: item.link,
              }));
              fileList.drugs.push(...results);
            } else {
              fileList.drugs.push(fileData);
            }
  
          } else if (subdirectory === "plans") {
            const fileName = encodeURIComponent(blob.name.split('/').pop());  
            const rscd = `&rscd=inline%3B%20filename%3D${fileName}`;  
            const cacheBuster = `&cb=${new Date().getTime()}`; // Cache-busting query parameter
    
            fileList.plans.push({  
              name: blob.name.split('/').pop(),  
              date: blob.properties.lastModified,  
              url: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}${sasTokenWithQuestionMark}${rscd}${cacheBuster}`  
            });
  
          } else {
            console.log("Uncategorized blob:", blob.name);
          }
  
        } else {
          console.log("Blob without subdirectory:", blob.name);
        }
      }
  
      console.log("Final File List:", fileList);
      setFiles(fileList);
  
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/CS")) {
      fetchFilesFromDirectory("ui/cs/");
    } else if (location.pathname.includes("/IFP")) {
      fetchFilesFromDirectory("ui/ifp/");
    }
  }, [location.pathname]);

  const FileDownloader = ({files}) => {
    const { fileName } = useParams();
    console.log("FileDownloader", fileName);
    
    const file = files.providers.find(f => f.name === fileName) || 
                 files.drugs.find(f => f.name === fileName) || 
                 files.plans.find(f => f.name === fileName);

    console.log("files", files);

    useEffect(() => {
      if (file) {
        window.location.href = file.url;
      }   
    }, [file]);

    // return file ? <div>Downloading {file.name}...</div> : <div>Downloading/Redirecting</div>;
    return null
    
  };

  // const FileDownloader = ({ files }) => {
  //   const { fileName } = useParams();
  //   const [fileContent, setFileContent] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);
  
  //   const file = files.providers.find(f => f.name === fileName) || 
  //                files.drugs.find(f => f.name === fileName) || 
  //                files.plans.find(f => f.name === fileName);

  //                console.log("file", file.name);
  
  //   useEffect(() => {
  //     if (file && file.name && file.name.includes('Drugs')) {
  //       window.location.href = file.url;
  //     }

  //     else if (file) {
  //       fetch(file.url)
  //         .then(response => {
  //           if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //           }
  //           return response.json();
  //         })
  //         .then(data => {
  //           setFileContent(data);
  //           setLoading(false);
  //         })
  //         .catch(error => {
  //           console.error('Error fetching the file:', error);
  //           setError('Error fetching the file');
  //           setLoading(false);
  //         });
  //     } else {
  //       setLoading(false);
  //     }
  //   }, [file]);
  
  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }
  
  //   if (error) {
  //     return <div>{error}</div>;
  //   }
  
  //   return fileContent ? (
  //     <pre>{JSON.stringify(fileContent, null, 2)}</pre>
  //   ) : (
  //     <div>File not found</div>
  //   );
  // };

  return (
    <Routes>
      <Route path="/IFP" element={<IFP files={files} />} />
      <Route path="/IFP/:fileName" element={<FileDownloader files={files} />} />
      <Route path="/CS" element={<CS files={files} />} />
      <Route path="/CS/:fileName" element={<FileDownloader files={files} />} />
    </Routes>
  );
};

export default App;